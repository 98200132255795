// Determine the environment-specific GTM ID
export const getGTMId = () => {
  const hostname = window.location.hostname;
  
  // Production environments
  if (hostname === 'proprt.io' || hostname.endsWith('.proprt.io')) {
    return 'GTM-WMBLNX4S'; // Production GTM ID
  }
  
  // Development, staging, or localhost environments
  return 'GTM-TXCQTKTW'; // Dev/staging GTM ID
}; 